import React, { useEffect } from 'react'
import { Divider, Row, Col } from 'antd'
import TransactionTable from '../components/TransactionHistory/TransactionTable'
import RedemptionCard from '../components/Redemption/RedemptionCard'
import ProviderCard from '../components/Provider/ProviderCard'
import TransactionCard from '../components/Transaction/TransactionCard'
import { useUsersState } from "../contexts/users";
import { User } from '../types/index'
import { actions } from '../contexts/users/actions'

function HomeView(props: { user: User }) {

  const { user } = props
  
  const {
    users,
    isUsersLoading
  } = useUsersState();

  useEffect(() => {
    actions.fetchUsers()
  }, [])

  return (
    <>
      <Row>
        <Col offset={1} span={22}>
          <TransactionCard users={users} isUsersLoading={isUsersLoading} />
        </Col>
        {/* <Col offset={2} span={10}>
          {user.admin ? <ProviderCard /> : <RedemptionCard />}
        </Col> */}
      </Row>
      <Divider />
      <Row>
        <Col offset={1} span={22}>
          <TransactionTable users={users} />
        </Col>
      </Row>
    </>
  )
}

export default HomeView
