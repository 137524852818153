import React, { useState } from "react";

import { Table, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const DataTableComponent = ({
  style,
  columns,
  data,
  isLoading,
  onChange,
  offset,
  limit,
  setOffset,
  naviroute,
  rowKey,
  setSelectedObj,
  selectedRowObj,
  pagination,
  scrollX
}) => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys);
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      onChange(newSelectedRowKeys, selectedRows);
    },
    type: 'checkbox'
  };

  return (
    <Spin spinning={isLoading} delay={500} size="large">
      <Table
        style={style}
        columns={columns}
        dataSource={data}
        sticky={true}
        pagination={
          pagination ?? {
            defaultPageSize: 10,
            showSizeChanger: false,
            position: ["bottomCenter"],
          }
        }
        rowKey={"address"}
        rowSelection={rowSelection}
      />
    </Spin>
  );
};

DataTableComponent.propTypes = {
  columnContentTypes: PropTypes.array,
  rows: PropTypes.array,
  headings: PropTypes.array,
  sortable: PropTypes.array,
  onSort: PropTypes.func,
  defaultSortDirection: PropTypes.string,
  initialSortColumnIndex: PropTypes.number,
  offset: PropTypes.number,
  setOffset: PropTypes.func,
  limit: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default DataTableComponent;
