import React, { useState, useEffect } from 'react'
import { Card, Button, Input, InputNumber, Select, Typography, Row, Col, Upload } from 'antd'
import DataTableComponent from '../DataTableComponent'
import TransctiontionModal from './TransactionModal'
import { User } from '../../types/index'
import { filterDuplicateUserAddresses } from '../../helpers/utils'
import { InputProps, SearchProps } from 'antd/es/input'
import { actions } from '../../contexts/tokens/actions'
import { useTokenDispatch, useTokenState } from '../../contexts/tokens';
import { actions as userActions } from '../../contexts/users/actions'
import { useUsersDispatch, useUsersState } from '../../contexts/users';

function TransactionCard(props: { users: [], isUsersLoading: false }) {
  
  const { users, isUsersLoading } = props

  const [filteredUsers, setFilteredUsers] = useState<string[]>(users);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [filteredAndSelectedUsers, setFilteredAndSelectedUsers] = useState<string[]>([]);
  const [amount, setAmount] = useState<number | undefined>()
  const [receiverAddresses, setReceiverAddresses] = useState<string[] | undefined>([])
  const [selectedFile, setSelectedFile] = useState<any>()

  const {
    isOwnershipTokenTransferring
  } = useTokenState()

  const dispatch = useTokenDispatch()
  const usersDispatch = useUsersDispatch()

  let columns: any = [
    {
      title: "Username",
      dataIndex: "commonName",
      align: "center",
      sorter: (a: any, b: any) => {
        const sa = selectedUsers.find((e: any) => e.userAddress === a.userAddress) ? 1 : 0;
        const sb = selectedUsers.find((e: any) => e.userAddress === b.userAddress) ? 1 : 0;
        const sasb = sb - sa;
        if (sasb === 0) {
          return a.commonName.localeCompare(b.commonName);
        } else {
          return sasb;
        }
      }
    },
    {
      title: "Organization",
      dataIndex: "organization",
      align: "center",
      sorter: (a: any, b: any) => {
        const sa = selectedUsers.find((e: any) => e.userAddress === a.userAddress) ? 1 : 0;
        const sb = selectedUsers.find((e: any) => e.userAddress === b.userAddress) ? 1 : 0;
        const sasb = sb - sa;
        if (sasb === 0) {
          return a.commonName.localeCompare(b.organization);
        } else {
          return sasb;
        }
      }
    },
    {
      title: "Address",
      dataIndex: "userAddress",
      align: "center",
      sorter: (a: any, b: any) => {
        const sa = selectedUsers.find((e: any) => e.userAddress === a.userAddress) ? 1 : 0;
        const sb = selectedUsers.find((e: any) => e.userAddress === b.userAddress) ? 1 : 0;
        const sasb = sb - sa;
        if (sasb === 0) {
          return a.commonName.localeCompare(b.userAddress);
        } else {
          return sasb;
        }
      }
    },
    {
      title: "Balance",
      dataIndex: "balance",
      align: "center",
      sorter: (a: any, b: any) => {
        const sa = selectedUsers.find((e: any) => e.userAddress === a.userAddress) ? 1 : 0;
        const sb = selectedUsers.find((e: any) => e.userAddress === b.userAddress) ? 1 : 0;
        const sasb = sb - sa;
        if (sasb === 0) {
          return a.balance - b.balance;
        } else {
          return sasb;
        }
      }
    },
  ];

  const handleChange = (value: any) => {
    setAmount(value);
  }
  const handleFilteredAndSelected = (filtered: any, selected: any) => {
    const optionMap = selected.reduce((m: any, o: any) => {
      return { ...m, [o.userAddress]: o }
    }, {})
    const filteredAndSelected = filtered.reduce((list: any, u: any) => {
      if (optionMap[u.userAddress]) {
        return list;
      } else {
        return [...list, u];
      }
    }, selected);
    setFilteredAndSelectedUsers(filteredAndSelected);
  }

  const handleSelect = (value: any, option: any) => {
    setSelectedUsers(option);
    setReceiverAddresses(option.map((o: any) => o.userAddress));
    handleFilteredAndSelected(filteredUsers, option);
  }

  const onChange: InputProps['onChange'] = (e: any) => {
    let filtered: any = users;
    if (e.target.value !== '') {
      filtered = users.filter((u: any) =>
          u?.commonName.toLowerCase().includes(e.target.value.toLowerCase())
       || u?.organization.toLowerCase().includes(e.target.value.toLowerCase())
      );
    }
    setFilteredUsers(filtered);
    handleFilteredAndSelected(filtered, selectedUsers);
  }

  const handleTransfer = async () => {
    try {
      if (amount && amount > 0 && receiverAddresses &&  receiverAddresses.length > 0) {
        await actions.transferTokenOwnership(dispatch, {
          to: receiverAddresses,
          value: receiverAddresses.map((r) => amount !== undefined ? amount * 100 : 0)
        })
        userActions.fetchUsers(usersDispatch)
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleTransferCSV = async () => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile.originFileObj);
      await actions.transferTokenOwnershipCSV(dispatch, formData)
      userActions.fetchUsers(usersDispatch)
    } catch (error) {
      console.log(error)
    }
  };

  const handleFileChange = (info: any) => {
    setSelectedFile(info.fileList[0])
  };

  return (
    <>
      <Typography.Title level={2}>Transfer</Typography.Title>
      <Card>
        <div> {/*style={{ alignItems: 'center', flexDirection: 'column' }}>*/}
          <Row>
            <Col offset={1} span={5}>
              <h4 className="text-[#202020] font-medium text-sm">Recipient</h4>
              <Input
                style={{ marginBottom: '10px', height: '35px', width: '20rem' }}
                onChange={onChange}
                placeholder='Search by username'
              />
            </Col>
            <Col offset={1} span={5}>
              <h4 className="text-[#202020] font-medium text-sm">Amount</h4>
              <InputNumber
                style={{ marginBottom: '10px', height: '35px', width: '20rem' }}
                controls={false}
                size={"large"}
                min={0.00}
                precision={2}
                defaultValue={amount}
                onChange={(e) => handleChange(e)}
                value={amount}
              />
            </Col>
            <Col offset={1} span={5}>
              <h4 className="text-[#202020] font-medium text-sm">&nbsp;</h4>
              <Button
                style={{ height: '35px', width: '10rem' }}
                type="primary"
                size="large"
                shape="round"
                disabled={(amount === 0
                        || amount === null
                        || amount === undefined
                        || receiverAddresses === undefined
                        || receiverAddresses.length === 0)}
                onClick={handleTransfer}
                loading={isOwnershipTokenTransferring}
              >
                Transfer
              </Button>
            </Col>
            <Col offset={1} span={5}>
              <div className="p-4 border-secondryD border rounded flex flex-col justify-around" style={{height: '42px', marginBottom: '16px'}}>
                <Upload
                  onChange={handleFileChange}
                  accept="application/csv"
                  multiple={false}
                  maxCount={1}
                >
                  <Button
                    style={{ height: '24px', width: '10rem' }}
                    type="dashed"
                    size="small"
                    shape="round"
                  >
                    Browse Files 📁
                  </Button>
                </Upload>
              </div>
              <Button
                style={{ height: '35px', width: '10rem' }}
                type="primary"
                size="large"
                shape="round"
                onClick={handleTransferCSV}
                loading={isOwnershipTokenTransferring}
                disabled={!selectedFile}
              >
                Upload CSV
              </Button>
            </Col>
          </Row>
          <Row>
            <DataTableComponent
              style={{ marginTop: '10px' }}
              columns={columns}
              data={filteredAndSelectedUsers.map((u: any) => ({...u, children: undefined, balance: `${((u.balance || 0) / 100).toFixed(2)}` }))}
              isLoading={isUsersLoading}
              pagination={true}
              scrollX="100%"
              onChange={handleSelect}
              naviroute={undefined}
              setSelectedObj={undefined}
              selectedRowObj={undefined}
              rowKey={undefined}
            />
          </Row>
        </div>
      </Card>
    </>
  )
}

export default TransactionCard
