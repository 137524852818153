import { actionDescriptors } from "./actions";

const reducer = (state, action) => {
  switch (action.type) {
    case actionDescriptors.resetMessage:
      return {
        ...state,
        success: false,
        message: null
      };
    case actionDescriptors.setMessage:
      return {
        ...state,
        success: action.success,
        message: action.message
      };
    case actionDescriptors.mintToken:
      return {
        ...state,
        isMintTokenSubmitting: true
      };
    case actionDescriptors.mintTokenSuccessful:
      return {
        ...state,
        token: action.payload,
        isMintTokenSubmitting: false
      };
    case actionDescriptors.mintTokenFailed:
      return {
        ...state,
        error: action.error,
        isMintTokenSubmitting: false
      };
    case actionDescriptors.fetchBalance:
      return {
        ...state,
        balanceLoading: true
      };
    case actionDescriptors.fetchBalanceSuccessful:
      return {
        ...state,
        balance: action.payload,
        balanceLoading: false
      };
    case actionDescriptors.fetchBalanceFailed:
      return {
        ...state,
        error: action.error,
        balanceLoading: false
      };
    case actionDescriptors.transferTokenOwnership:
      return {
        ...state,
        isOwnershipTokenTransferring: true
      };
    case actionDescriptors.transferTokenOwnershipCSV:
      return {
        ...state,
        isOwnershipTokenTransferring: true
      };
    case actionDescriptors.transferTokenOwnershipSuccessful:
      return {
        ...state,
        tokenOwnership: action.payload,
        isOwnershipTokenTransferring: false
      };
    case actionDescriptors.transferTokenOwnershipFailed:
      return {
        ...state,
        error: action.error,
        isOwnershipTokenTransferring: false
      };
    case actionDescriptors.destroyToken:
      return {
        ...state,
        isTokenBeingDestroyed: true
      };
    case actionDescriptors.destroyTokenSuccessful:
      return {
        ...state,
        tokenDestroyed: action.payload,
        isTokenBeingDestroyed: false
      };
    case actionDescriptors.destroyTokenFailed:
      return {
        ...state,
        error: action.error,
        isTokenBeingDestroyed: false
      };
    case actionDescriptors.redeemToken:
      return {
        ...state,
        isTokenRedeeming: true
      };
    case actionDescriptors.redeemTokenSuccessful:
      return {
        ...state,
        tokenRedeem: action.payload,
        isTokenRedeeming: false,
        tokenRedemption: true,
      };
    case actionDescriptors.redeemTokenFailed:
      return {
        ...state,
        error: action.error,
        isTokenRedeeming: false,
        tokenRedemption: false,
      };
    case actionDescriptors.fetchTokenReserve:
      return {
        ...state,
        isTokenReserveLoading: true
      };
    case actionDescriptors.fetchTokenReserveSuccessful:
      return {
        ...state,
        reserve: action.payload,
        isTokenReserveLoading: false
      };
    case actionDescriptors.fetchTokenReserveFailed:
      return {
        ...state,
        error: action.error,
        isTokenReserveLoading: false
      };

    default:
      throw new Error(`Unhandled action: '${action.type}'`);
  }
};

export default reducer;
